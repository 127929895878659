<template>
    <Toast />

    <LibraryUpload v-model:visible="imageUploadDialog" :path="endpoints.uploadlibrary" :slug="channel" @data="libraryUploadGetData" @finished="libraryUploadidFinished" />

    <Toolbar>
        <template #start>

            <div class="inline sm:hidden">
                <SplitButton label="Speichern" icon="pi pi-save" :model="items" :disabled="disabled" />
            </div>
            <div class="hidden sm:flex sm:justify-items-start">
                <ExclamationTriangleIcon class=" text-orange-600 h-10 w-10 mt-1 mr-1" v-tooltip.bottom="'Speichern nur mit Bild möglich'" v-if="disabled" />
                <Button @click="save_changes" v-tooltip.bottom="'Änderungen Speichern'" label="Speichern" class="w-auto mr-4 p-button-success" icon="pi pi-save" :loading="loader" size="small" :disabled="disabled" />

                <Divider layout="vertical" />
                <Button v-tooltip.bottom="'Preview Guide'" @click="preview" label="Vorschlau" class="mr-2 p-button w-auto" icon="pi pi-eye" size="small" />
                <Button v-tooltip.bottom="'Guide schliessen'" @click="close_tab" label="Schliessen" class="mr-2 p-button-danger w-auto" icon="pi pi-times-circle" size="small" />
            </div>

        </template>
        <template #end>
            <div class="hidden sm:inline-block">
                <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes" />
            </div>
        </template>
    </Toolbar>

    <TabView ref="tabview4">
        <TabPanel>
            <template #header>
                <i class="pi pi-file mr-2"></i>
                <span class="mr-3">Meta</span>
            </template>
            <div class="md:flex flex-row md:my-5">
                <div class="w-auto md:w-2/4 m-2">
                    <Accordion class="accordion-custom" :activeIndex="0">
                        <AccordionTab>
                            <template #header>
                                <span class="mr-2 font-bold">Guide Daten</span>
                            </template>

                            <div class="mb-16">
                                <h3 class="my-1 font-semibold">Beschreibung</h3>
                                <Editor v-model="blog.text_description" class="w-full h-28">
                                    <template v-slot:toolbar>
                                        <span class="ql-formats">
                                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                        </span>
                                    </template>
                                </Editor>
                            </div>

                            <div class="my-6">
                                <h3 class="my-1 font-semibold">YouTube Video</h3>
                                <InputText id="youtube_video" class="w-full" v-model="blog.youtube_video" />

                                <iframe v-if="validateYouTubeUrl(youtube_video)" :key="youtube_video" class="w-full my-5" height="315" :src="convertYouTubeURL(youtube_video) " title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>

                        </AccordionTab>
                    </Accordion>
                </div>

                <div class="w-auto md:w-2/4 m-2">
                    <div class="">
                        <Accordion class="accordion-custom" :activeIndex="0">
                            <AccordionTab>
                                <template #header>
                                    <span class="mr-2 font-bold">Metadaten</span>
                                </template>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <Dropdown id="blog_status" v-model="blog.status" :options="blog_status" optionLabel="name" optionValue="value" class="w-full" />
                                        <label for="blog_status">Status</label>
                                    </span>
                                </div>

                                <div class="field col-12 my-7">
                                    <span class="p-float-label">
                                        <InputText id="title" class="w-full" v-model="blog.title" />
                                        <label for="title">Titel</label>
                                    </span>
                                </div>

                                <div class="field col-12 my-7">
                                    <Chips v-model="blog.tags" separator="," class="" style=" width: 100%;" id="tags" />
                                </div>

                                <div class="my-7">
                                    <span class="p-float-label">
                                        <MultiSelect id="blog_categories" v-model="new_data_categories" :options="blog_categories" optionLabel="name" optionValue="value" class="w-full" filter :maxSelectedLabels="0" selectedItemsLabel="{0} Kategorien" />
                                        <label for="blog_categories">Kategorien</label>
                                    </span>
                                </div>
                                <div class="flex flex-wrap" v-if="image_library.length > 0">
                                    <UploadImageButton :imagelibrary="image_library" @delete="delete_image" label="Hauptbild" :image="blog.header_image" :workflow_id="workflow_id" @uploaded-image="imageUploaded" image-type="header_image" :aspect-ratio="{ aspectRatio: 16 / 9 }" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-image mr-2"></i>
                                    <span class="mr-2 font-bold">Bilder Bibliotek</span>
                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(image_library.length) }}</span>
                                </template>

                                <ImageGallery :image-library="image_library" @update:imageLibrary="image_library = $event" />
                                <div>
                                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="field col-12 mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-history mr-2"></i>
                                    <span class="mr-2 font-bold">Änderungsverlauf</span>

                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(changes.length) }}</span>
                                </template>
                                <div v-if="changes">
                                    <div v-for="(change, index) of changes.sort((a, b) => b.date - a.date)" :key="index" class="my-8 bg-yellow-700/20 p-6 rounded-lg">
                                        <div class="text-xs">
                                            <Chip :label="change.user" icon="pi pi-user" class="m-1" />
                                            <Chip :label="swiss_date_time(change.date)" icon="pi pi-clock" class="m-2" />
                                        </div>
                                        <div class="bg-primary-100 text-white m-2 p-2 rounded-md">
                                            <span class=""> {{ change.message }}</span>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </TabPanel>

    </TabView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <Dialog v-model:visible="blog_text_vorschlag_dialog" :style="{ width: '800px' }" header="Teaser Vorschlag" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            {{ blog_text_vorschlag }}
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!--------------- Edit Title Image ------------------>
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImageDialog" :style="{ width: '80%' }" header="Titel Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">

            <div v-if="edit_header_image == false" class="field col-12 m-3">
                <img :src="blog.header_image" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>

            <span class="inline-block h-48 w-48 overflow-hidden rounded-full bg-gray-100 my-10" v-if="edit_header_image == false && !blog.header_image">
                <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
            </span>
            <Button v-if="edit_header_image == false" label="Bild Bearbeiten" @click="edit_header_image = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />

            <div v-if="edit_header_image == true" class="field col-12 mt-3">
                <cropper ref="title_image_cropper" :stencil-props="{ aspectRatio: 16 / 9 }" :src="blog.header_image" :auto-zoom="true" :auto-center="true" />
            </div>

            <div v-if="edit_header_image == true" class="field col-12 mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>

            <div v-if="edit_header_image == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="cropImage(title_image_cropper, 'header_image', 'image123')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
        </div>

    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Preview Image ------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImagePreviewDialog" :style="{ width: '80%' }" header="Titel Vorschau-Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div v-if="edit_header_image_preview == false" class="field col-12 mt-3">
                <img :src="blog.header_image_preview" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <Button v-if="edit_header_image_preview == false" label="Bild Bearbeiten" @click="edit_header_image_preview = true, blog.header_image_preview = blog.header_image" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit_header_image_preview == true" class="field col-12 mt-3">
                <cropper ref="title_image_cropper_preview" :stencil-props="{ aspectRatio: 1 / 1 }" :src="blog.header_image_preview" :auto-zoom="true" :auto-center="true" />
            </div>
            <div v-if="edit_header_image_preview == true" class="field col-12 mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image_preview = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>
            <div v-if="edit_header_image_preview == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="crop_header_image(title_image_cropper_preview, 'header_image_preview')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------- GPT Dialog -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="chatGptDialog" :style="{ width: '80%' }" header="Inhalte mit AI erstellen" :modal="true" class="p-fluid">
        <ChatGPT :questions="chatGptQuestions" @chat-callback="update_text_content" />
    </Dialog>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, defineEmits, inject, provide, onBeforeMount } from 'vue'
import { EnvelopeIcon, ArchiveBoxIcon, CloudIcon, ExclamationTriangleIcon } from '@heroicons/vue/20/solid';
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ChatGPT from '@/core/components/Components/Main-GPT.vue';
import InputGroup from 'primevue/inputgroup';
import Simplestore from '@/core/var/Simplestore';
import { swiss_date_time, checkDuplicate, preview_dataset, slugify } from '@/core/var/tools'
import { uploadSingleImage } from '@/core/var/crud'
import LibraryUpload from '@/core/components/Components/Main-Library-Upload.vue'
import UploadImageButton from '@/core/components/Components/Main-UploadImage-Button2.vue';
import ImageGallery from '@/core/components/Components/Main-Image-Library.vue'

const props = defineProps({
    redis_key: { type: String }
})

const content_blocks = ref(['standard_text',
    'standard_title',
    'standard_image',
    'cta_button',
    'html_block',
    'quote_block',
    'image_text',
    'comparsion_block',
    'quoting_logo'])

const channel = ref(useCustomerStore().getCustomer.customer)
provide('channel', channel)
const SERVICE_URL = inject('SERVICE_URL')


const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const imageUploadDialog = ref(false)
const editTitleImageDialog = ref(false)
const editTitleImagePreviewDialog = ref(false)
const edit_header_image_preview = ref(false)
const edit_header_image = ref(false)
const workflow_id = ref("")
const new_data_categories = ref();
const blog = ref([])
const blog_categories = ref([])
const blog_tags = ref([])
const blog_sort = ref([])
const blog_status = ref([])
const users = ref([])
const data_content = ref([])
const image_library = ref([])
const activeImageIndex = ref(0)
const displayGallery = ref(false)
const changes = ref([])
const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const blog_title = ref(null);
const blog_text_vorschlag = ref(null);
const blog_text_vorschlag_dialog = ref(false);
const title_image_cropper = ref();
const emits = defineEmits(['editorCallback'])
const title_image_cropper_preview = ref()
const disabled = ref(true)
const disabled_sku = ref(false)
const youtube_video = ref(false) 


const items = ref([
    {
        label: 'Änderungen zurücksetzen',
        icon: 'pi pi-refresh',
        command: () => {
            reset_changes()
        }
    },
    {
        label: 'Vorschau',
        icon: 'pi pi-eye',
        command: () => {
            preview()
        }
    },
    {
        label: 'Schließen',
        icon: 'pi pi-times',
        command: () => {
            close_tab()
        }
    }

]);

const MODULE = 'guides';

const endpoints = {
    get: `${MODULE}/get`,
    getsingle: `${MODULE}/get-single`,
    savesingle: `${MODULE}/save-single`,
    getsystem: `get-system`,
    getusers: `get-users`,
    getcategories: `${MODULE}/categories`,
    gettags: `${MODULE}/tags`,
    save: `${MODULE}/save`,
    uploadsingle: `upload-image`,
    uploadlibrary: `${MODULE}/upload-library-image`,
    preview: `${MODULE}/preview/save`
}
provide('upload_endpoint', endpoints.uploadsingle);

onBeforeMount(async () => {
    loader.value = true;
    await getData();
    await bootstrap();
    loader.value = false;
})

watch(loader, res => {
    Simplestore.spinner = res;
});


watch(workflow_id, res => {
    provide('workflow_id', res)
})

const bootstrap = async () => {
    try {
        const [systemResponse, usersResponse, categoriesResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/${endpoints.getsystem}`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/${endpoints.getusers}`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getcategories}`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get}`)
        ]);

        blog_categories.value = categoriesResponse.data;
        blog_sort.value = systemResponse.data.blog_sort;
        blog_status.value = systemResponse.data.blog_status;
        users.value = usersResponse.data;
        youtube_video.value = blog.value.youtube_video
    } catch (error) {
        console.error("Error fetching system and users data:", error);
    }
};


const getData = async () => {
    const payload = { redis_key: props.redis_key };
    try {
        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`, payload);
        const { data } = response;

        blog.value = data;
        workflow_id.value = data.workflow_id;
        data_content.value = data.content;
        blog_title.value = data.title;
        changes.value = data.changes;
        image_library.value = data.image_library;
        new_data_categories.value = data.category;
    } catch (error) {
        console.error("Error fetching article data:", error);
    }
};

const GPT_Primer = (blog_title) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle einen Text zum Thema ${blog_title}`,
            button_name: `Text automatsch erstellen`,
        },
        {
            question: `Erstelle einen Text zum Thema ${blog_title} und fasse ihn mit 100 Wörtern zusammen`,
            button_name: "Text-Zusammenfassung (100 Wörter)",
        },
    ];
}

const update_text_content = res => {
    blog.value.text_content = blog.value.text_content + res.chatGptAnswers;
    chatGptDialog.value = false;
}

const preview = () => {
    const workload = {
        redis_key: blog.value.redis_key,
        content: blog.value.content,
        title: blog.value.title,
        teaser: blog.value.teaser,
        header_image: blog.value.header_image,
    };

    axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.preview}`, workload)
        .then(response => {
            preview_dataset(blog.value.slug, SERVICE_URL, MODULE)
        })
}

const close_tab = () => {
    confirm.require({
        message: 'Sind Sie sicher, dass Sie diesen Guide schliessen wollen? Alle nicht gespeicherten Änderungen gehen verloren.',
        header: 'Guide schliessen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Guide schliessen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            emits('editorCallback',
                {
                    "action": "close_tab",
                    "redis_key": props.redis_key
                }
            )
        }
    })
}

const showGallery = (index) => {
    activeImageIndex.value = index
    displayGallery.value = true
}

const update_template_data = (e) => {
    if (e.action == "save") {
        data_content.value[e.index] = e.template_data
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gespeichert', life: 3000 })
    } else if (e.action == "delete") {
        data_content.value.splice(e.index, 1)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gelöscht', life: 3000 })
    } else if (e.action == "add") {
        data_content.value.push(e.template_data)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt hinzugefügt', life: 3000 })
    }
}

const save_changes = async () => {
    loader.value = true;
    const { value } = blog;


    const checkTitle = await checkDuplicate(blog.value.title, blog.value.workflow_id, 'driveplus/articles/check-title')

    if (checkTitle.msg == true) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Dieser Titel existiert bereits', life: 3000 })
        loader.value = false;
        return;
    }

    value["content"] = data_content.value
    value["changes"] = changes.value
    value["image_library"] = image_library.value
    value["category"] = new_data_categories.value

    try {
        const response = await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.savesingle}`,
            value
        );

        const { key } = response.data;

        toast.add({
            severity: "success",
            summary: `${value.title} gespeichert`,
            detail: "Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });

        blog.value.redis_key = key
        loader.value = false;
        disabled_sku.value = true

    } catch (error) {
        console.error(error);
    }
};

const setRedisKey = (slug, mdle) => {
    return `${channel.value}:${mdle}:${slug}`
}

const reset_changes = async () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            try {
                const payload = { redis_key: props.redis_key };
                const response = await axios.post(
                    `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`,
                    payload
                );
                blog.value = response.data;
            } catch (error) {
                console.error(error);
            }
        },
    });
};


const onRowReorder = (e) => {
    if (e.dragIndex != e.dropIndex) {
        data_content.value = e.value
    }
}

const crop_header_image = async (image, image_type) => {

    await uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 3000,
        });

        blog.value.header_image = res.data.image_url;
        edit_header_image.value = false;
    });
}


const UploadImage = (image, image_type) => {
    return new Promise((resolve, reject) => {
        uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
            resolve(res.data.image_url);
        }).catch(err => {
            reject(err);
        });
    });
}

const cropImage = async (image, image_type, reference) => {
    loader.value = true;
    UploadImage(image, image_type).then(res => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 2000,
        });
        [reference].value = res;
        loader.value = false;
    });
}

const libraryUploadGetData = (res) => {
    blog.value['image_library'].push({ image: res });
}

watch(blog, res => {
    disabled.value = false;
    // if (res.header_image.length > 0) {
    //     disabled.value = false;
    // }
})

const libraryUploadidFinished = (res) => {
    if (res === true) {
        imageUploadDialog.value = false;
    }
}

const openEditDialog = res => {

    editTitleImageDialog.value = res;
    console.log(editTitleImageDialog.value);
}

const imageUploaded = res => {
    blog.value[res.type] = res.image;
    disabled.value = false;
}

const delete_image = res => {
    blog.value[res.type] = false;
    disabled.value = false;
}

function convertYouTubeURL() {
    
    const url = youtube_video.value;

    if(validateYouTubeUrl(url)){
        const baseEmbedURL = 'https://www.youtube.com/embed/';
        let videoId;
        if (url.includes('youtu.be')) {
            videoId = url.split('youtu.be/')[1];
        } else if (url.includes('youtube.com/shorts')) {
            videoId = url.split('youtube.com/shorts/')[1];
        } else if (url.includes('v=')) {
            videoId = url.split('v=')[1];
        }

        if (!videoId) {
            console.error('Invalid YouTube URL format');
            throw new Error('Invalid YouTube URL format');
        }

        const ampersandPosition = videoId.indexOf('?');
        if (ampersandPosition != -1) {
            return baseEmbedURL + videoId.substring(0, ampersandPosition);
        }
        return baseEmbedURL + videoId;
    }
    else{
        return false;
    }
}
function validateYouTubeUrl(url) {
    const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
    return pattern.test(url);
}
</script>
<style>
.p-chips-multiple-container {
    @apply w-full;
}
.p-chips-multiple-container input{
    @apply w-full rounded-md border-gray-300;
}
</style>
